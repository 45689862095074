import { GetServerSideProps } from 'next';
import { NavBarContent } from '@/components/Navbar';
import { api, site } from '@/services/api';
import InstitutionalPage from '@/components/Institutional/InstitutionalPage';
import getConcurrentPromises from '@/utils/getConcurrentPromises';
import { ApplySchema } from '@/components/Internals/InternalSchema';
import Layout from '@/components/Layout';
import { InstitutionalProps } from '../privacidade';

interface NavBarContentData {
  data: NavBarContent;
}

export interface AboutPageProps extends InstitutionalProps {
  institutionalData: {
    data: {
      cabecalho: InstitutionalProps['institutionalData']['data']['cabecalho'];
      conteudo: InstitutionalProps['institutionalData']['data']['conteudo'];
      breadcrumbs: InstitutionalProps['institutionalData']['data']['breadcrumbs'];
      equipe: {
        nome: string;
        cargo: string;
        url: string;
        imagem: {
          url: string;
          blurhash: string;
        };
      }[];
    };
  };
}

export default function About({ institutionalData, navBarData, ssr, schema }: AboutPageProps) {
  const { data } = institutionalData;
  const { cabecalho, breadcrumbs, conteudo, equipe } = data;

  return (
    <Layout layoutData={{ segmentacoes: [], tagsData: ssr, navBarData }}>
      {schema && <ApplySchema schema={schema} />}
      <InstitutionalPage
        text={conteudo}
        title="Quem Somos"
        headerImg={{ url: cabecalho.imagem.url, blurhash: cabecalho.imagem.blurhash, srcset: cabecalho.imagem.srcset }}
        breadcrumbs={breadcrumbs}
        members={equipe}
        type="sobre"
      />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async context => {
  const { host } = context.req.headers;

  const mainReq = { promise: site.get(`/sobre/`), link: `/sobre/` };
  const responseMenuReq = { promise: api.get<NavBarContentData>(`/componentes/menu/`), link: `/componentes/menu/` };

  const { promiseData, data, statusCode } = await getConcurrentPromises({
    mainPromise: mainReq,
    navBarPromise: responseMenuReq,
    host,
  });

  if (data.redirect || data.notFound) return data;

  const navBarData: NavBarContentData = promiseData[responseMenuReq.link].data.data;
  const { ssr, headers, schema } = data;

  context.res.setHeader('Cache-Control', headers['Cache-control']);
  context.res.setHeader('Expires', headers.Expires);
  context.res.statusCode = statusCode;

  const institutionalData = data;

  return {
    props: {
      institutionalData,
      navBarData,
      ssr,
      headers,
      schema,
    },
  };
};
